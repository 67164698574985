import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export interface Toast {
  severity: 'success' | 'info' | 'warning' | 'danger';
  summary: string;
  detail: string;
  icon?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastSubject = new Subject<Toast>();

  // Observable pour les composants qui veulent écouter les nouveaux toasts
  public toast$: Observable<Toast> = this.toastSubject.asObservable();

  // Méthode pour afficher un toast de succès
  success(message: string, title: string = ''): void {
    this.showToast('success', title || 'Succès', message, '✔️');
  }

  // Méthode pour afficher un toast d'information
  info(message: string, title: string = ''): void {
    this.showToast('info', title || 'Information', message, 'ℹ️');
  }

  // Méthode pour afficher un toast d'avertissement
  warn(message: string, title: string = ''): void {
    this.showToast('warning', title || 'Avertissement', message, '⚠️');
  }

  // Méthode pour afficher un toast d'erreur
  error(message: string, title: string = ''): void {
    this.showToast('danger', title || 'Erreur', message, '❌');
  }

  // Méthode privée pour émettre un nouveau toast
  private showToast(severity: Toast['severity'], summary: string, detail: string, icon?: string): void {
    this.toastSubject.next({ severity, summary, detail, icon });
  }
}
