<div class="box article">
  <div class="article-title">
    {{ article.title}}
  </div>
  <div class="article-content">
    {{ article.content}}
  </div>
  <div class="article-info">
    <div class="like-div">
      <div>
        Likes: {{ article.nbLikes }}
      </div>
      <div>
        <img ngSrc="assets/img/coeur.gif" width="23" height="23" (click)="like()" class="like" alt="like" />
      </div>
    </div>
    <div class="comment-div">
      <div>
        Commentaires: {{ article.nbComments }}
      </div>
      <div>
        <img ngSrc="assets/img/comment.png" width="19" height="23" (click)="comment()" class="comment" alt="comment" />
      </div>
    </div>
  </div>
  <div class="article-action">
<!--    <button (click)="like()">Like</button>-->


  </div>
    <div class="article-by">
      Par: {{ article.user.name }}
      le: {{ article.dateCreated | date:'dd/MM/yyyy à H:mm' }}
    </div>
</div>
