import { Injectable } from '@angular/core';
import {HttpService, ResponseDto} from '../http.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Token} from '../../Models/token';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private isLogged = new BehaviorSubject<boolean>(false);
  public isLogged$: Observable<boolean> = this.isLogged.asObservable();

  constructor(private httpService: HttpService, private router: Router) {
    this.isLogged.next(false);

  }

  // Fonction de connexion
  async login(name: string, password: string): Promise<void> {
    // Appel à l'API pour la connexion
    const res:ResponseDto<Token> = await this.httpService.post('auth/login', {name: name, password: password});
    // Traitement de la réponse
    if (res.success && res.data && res.data.token) {
      sessionStorage.setItem('token', res.data.token);
      sessionStorage.setItem('tokenValidity', res.data.tokenValidity.toString());
      sessionStorage.setItem('refreshToken', res.data.refreshToken);
      this.isLogged.next(true);
    }
    console.log(res.data);
    console.log(typeof(res.data?.email));
    if(!res.success && res.data && typeof(res.data.email) == "string"){
      this.router.navigate(['/activate-account'], {queryParams: {email: res.data.email}});
    }
  }

  async logout(): Promise<void> {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('tokenValidity');
    sessionStorage.removeItem('refreshToken');
    this.isLogged.next(false);
  }

  async isLoggedUser(): Promise<boolean> {
    if (sessionStorage.getItem('token') && sessionStorage.getItem('tokenValidity') && sessionStorage.getItem('refreshToken')) {
      const res:ResponseDto<null> = await this.httpService.get('Auth/isLogged');
      if (res.success) {
        this.isLogged.next(true);
        return true;
      }
    }
    this.isLogged.next(false);
    return false;
  }
}
