import { Component, OnInit } from '@angular/core';
import { ToastService, Toast } from '../../Services/toast.service';
import {NgIf, NgFor, NgClass} from '@angular/common';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NgClass]
})
export class ToastComponent implements OnInit {
  public toasts: Toast[] = [];

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    // Écouter les nouveaux toasts
    this.toastService.toast$.subscribe((toast) => {
      this.toasts.push(toast);

      // Supprimer le toast après 5 secondes
      setTimeout(() => this.removeToast(toast), 5000);
    });
  }

  // Méthode pour supprimer un toast
  removeToast(toast: Toast): void {
    const index = this.toasts.indexOf(toast);
    if (index !== -1) {
      this.toasts.splice(index, 1);
    }
  }
}
