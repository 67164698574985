import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import {MaintenanceComponent} from './Components/maintenance/maintenance.component';
import {RouterOutlet} from '@angular/router';
import {Setting} from './Models/setting.model';
import {ToastComponent} from './Components/toast/toast.component';
import {ToastService} from './Services/toast.service';
import {HttpService} from './Services/http.service';
import {HeaderComponent} from './Components/Template/header/header.component';
import {FooterComponent} from './Components/Template/footer/footer.component';
import { environment } from '../environnements/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    NgIf,
    MaintenanceComponent,
    RouterOutlet,
    ToastComponent,
    HeaderComponent,
    FooterComponent,
  ],
  standalone: true
})
export class AppComponent implements OnInit {
  public setting: Setting | null = null;
  public oldSetting: Setting | null = null;
  constructor(private toastService: ToastService, private httpService: HttpService) {
    console.log(environment.apiUrl);
  }

  async ngOnInit(): Promise<void> {
    await this.getSetting();
  }

  async getSetting(): Promise<void> {
    const response = await this.httpService.get<Setting>("setting/GetSetting");
    if(response.data){
      this.setting = response.data;
      if(this.oldSetting?.maintenance !== this.setting?.maintenance){
        this.oldSetting = this.setting;
        if(this.setting.maintenance){
          this.toastService.info('Veuillez nous excuser pour la gêne occasionnée', 'Site en Maintenance');
        }
      }
    }
  }
}
