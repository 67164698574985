import { Routes } from '@angular/router';
import {AccueilComponent} from './Components/accueil/accueil.component';
import {RegisterComponent} from './Components/Auth/register/register.component';
import {ActivateAccountComponent} from './Components/Auth/activate-account/activate-account.component';

export const routes: Routes = [
  {
    path: '',
    component: AccueilComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'activate-account',
    component: ActivateAccountComponent
  },
  {
    path: 'activate-account/:optionalParam',
    component: ActivateAccountComponent
  }
];
