import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Button, ButtonDirective} from 'primeng/button';
import {FloatLabelModule} from "primeng/floatlabel";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {PasswordModule} from "primeng/password";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Router, RouterLink} from "@angular/router";
import {StyleClassModule} from "primeng/styleclass";
import {InputSwitchModule} from 'primeng/inputswitch';
import {CommonModule} from '@angular/common';
import {HttpService, ResponseDto} from '../../../Services/http.service';
import {MottoHabbo} from '../../../Models/motto-habbo';
import {LoginComponent} from '../login/login.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ActivateAccountComponent} from '../activate-account/activate-account.component';
import {SpinnerModule} from 'primeng/spinner';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    ButtonDirective,
    FloatLabelModule,
    InputTextModule,
    PaginatorModule,
    PasswordModule,
    ReactiveFormsModule,
    RouterLink,
    StyleClassModule,
    InputSwitchModule,
    CommonModule,
    FormsModule,
    Button,
    LoginComponent,
    ProgressSpinnerModule,
    ActivateAccountComponent,
    SpinnerModule
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnInit {
  email?: string | undefined | null;
  habbo:boolean = false;
  @ViewChild('habbo') habboSwitch: any;  // Référence à l'élément du DOM
  habboMoto!:MottoHabbo;
  registerForm!: FormGroup;
  isSubmitted = false;
  habboSubmited = false;
  constructor(private httpService: HttpService, private router: Router) {
    this.registerForm = new FormGroup({
      name: new FormControl(''),
      email: new FormControl(''),
      password: new FormControl(''),
      confirmPassword: new FormControl(''),
      habboName: new FormControl(''),
    });
  }

  async ngOnInit(): Promise<void> {
  }

  async checkHabboName(){
    this.habboSubmited = true;
    var response: ResponseDto<MottoHabbo> = await this.httpService.post<MottoHabbo>('user/insertValidationHabboName', {habboName: this.registerForm.value.habboName});
    if(response.data) {
      this.habboMoto = response.data;
    }
    this.habboSubmited = false;
  }

   // Méthode appelée lors de la soumission du formulaire
  async onSubmit() :Promise<void> {
    if (this.registerForm.valid) {
      this.isSubmitted = true;
      console.log(this.habbo);
      if(!this.habbo){
        this.registerForm.removeControl('habboName');
      }
      // Vous pouvez envoyer les données à votre backend ici
      var res:ResponseDto<null> = await this.httpService.post('auth/register', this.registerForm.value);
      if(res.success){
        this.email = res.data;
      } else {
        this.isSubmitted = false;
      }
    } else {
      this.registerForm.markAllAsTouched(); // Affiche les erreurs sur tous les champs
    }
  }

  async setHabbo(event: any){
    this.habbo = event.checked;
    if(this.habbo){
      this.registerForm.addControl('habboName', new FormControl(''));
    } else {
      this.registerForm.removeControl('habboName');
    }
  }
}
