import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { Injectable } from '@angular/core';
import { environment} from '../../environnements/environment';
import {ToastService} from './toast.service';

// Interface pour la réponse générique
export interface ResponseDto<T> {
  success: boolean;
  message?: string;
  data?: T;
}

// Types pour les callbacks
type SuccessCallback<T> = (response: ResponseDto<T>) => void;
type ErrorCallback = (error: string) => void;

@Injectable({
  providedIn: 'root'  // Cela le rend accessible dans toute l'application
})

// Classe HttpService
export class HttpService {
  private axiosInstance = axios.create({
    baseURL: environment.apiUrl, // Remplacez par votre base URL
    timeout: 10000,
  });

  constructor(private toast: ToastService) {
    // Ajouter un intercepteur pour toutes les requêtes
    this.axiosInstance.interceptors.request.use(async (config) => {
      const token = sessionStorage.getItem('token');
      const tokenValidity = sessionStorage.getItem('tokenValidity'); // Date d'expiration en UTC (en ms)
      const refreshToken = sessionStorage.getItem('refreshToken');

      // Exclure les appels de rafraîchissement du token pour éviter une boucle infinie
      if (config.url?.includes('auth/refreshToken')) {
        return config; // Ignorer l'interception pour cette requête
      }

      if (token && tokenValidity && refreshToken) {
        const currentUtcTime = new Date().getTime(); // Temps actuel en ms (UTC)
        const tokenExpiryTime = Number(Date.parse(tokenValidity)); // Convertir la validité en nombre

        // Vérifier si le token va expirer dans moins de 2 minutes
        if (currentUtcTime > tokenExpiryTime - 60000) {
          try {
            console.log('Token presque expiré, rafraîchissement en cours...');
            const response = await this.axiosInstance.post(
              'auth/refreshToken',
              { refreshToken },
              {
                headers: {
                  Authorization: `Bearer ${token}`, // Transmettre le token dans les en-têtes
                },
              }
            );

            if (response.data.success && response.data.data) {
              const newToken = response.data.data.token;
              const newTokenValidity = response.data.data.tokenValidity;
              const newRefreshToken = response.data.data.refreshToken;

              // Stocker les nouveaux tokens et leur validité
              sessionStorage.setItem('token', newToken);
              sessionStorage.setItem('tokenValidity', newTokenValidity.toString());
              sessionStorage.setItem('refreshToken', newRefreshToken);

              // Mettre à jour l'en-tête Authorization avec le nouveau token
              config.headers['Authorization'] = `Bearer ${newToken}`;
            } else {
              sessionStorage.removeItem('token');
              sessionStorage.removeItem('tokenValidity');
              sessionStorage.removeItem('refreshToken');
              console.warn('Impossible de rafraîchir le token, réponse invalide.');
            }
          } catch (error) {
            console.error('Erreur lors du rafraîchissement du token :', error);
            this.toast.error('Session expirée. Veuillez vous reconnecter.');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('tokenValidity');
            sessionStorage.removeItem('refreshToken');
            window.location.href = '/'; // Rediriger l'utilisateur vers la page de connexion
            throw error; // Interrompre la requête en cours
          }
        } else {
          // Ajouter le token actuel dans les en-têtes
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      }
      return config;
    });

    // Ajouter un intercepteur pour les réponses
    this.axiosInstance.interceptors.response.use(
      (response) => {
        // Vous pouvez effectuer des actions ici pour chaque réponse
        return response;
      },
      (error) => {
        // Gérer les erreurs ici globalement si nécessaire
        return Promise.reject(error);
      }
    );
  }

  // Méthode de gestion des erreurs par défaut
  private defaultErrorHandler(error: any): string {
    if (error.response && error.response.data && error.response.data.message) {
      this.toast.error(error.response.data.message);
      return error.response.data.message;
    }
    return error.message || 'Une erreur inconnue est survenue.';
  }

  // Méthode pour gérer les succès
  private defaultSuccessHandler<T>(response: AxiosResponse<T>): ResponseDto<T> {
    // Si la réponse contient déjà un objet avec { success, message, data }, retourne-le tel quel
    const responseData = response.data as any;

    // Vérifier si `responseData` est déjà au format attendu
    if (responseData.success !== undefined && responseData.data !== undefined) {
      if(responseData.message && responseData.success){
        this.toast.success(responseData.message);
      } else if (responseData.message && !responseData.success) {
        this.toast.error(responseData.message);
      }
      return responseData; // Ne pas ré-encapsuler si c'est déjà bien formaté
    }

    // Sinon, encapsuler normalement
    return {
      success: true,
      data: response.data,
      message: response.statusText,
    };
  }

  // Méthode GET
  async get<T>(
    url: string,
    params?: Record<string, any>, // Ajout pour gérer les paramètres GET
    config?: AxiosRequestConfig,
    onSuccess?: SuccessCallback<T>,
    onError?: ErrorCallback
  ): Promise<ResponseDto<T>> {
    try {
      // Fusionner les paramètres dans la configuration
      const finalConfig: AxiosRequestConfig = {
        ...config,
        params, // Ajout des paramètres GET ici
      };

      // Effectuer la requête GET avec Axios
      const response = await this.axiosInstance.get<T>(url, finalConfig);

      // Gérer le succès
      const result = this.defaultSuccessHandler(response);
      onSuccess?.(result);
      return result;
    } catch (error: any) {
      // Gérer l'erreur
      const errorMessage = this.defaultErrorHandler(error);
      onError?.(errorMessage);
      return { success: false, message: errorMessage };
    }
  }

  // Méthode POST
  async post<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
    onSuccess?: SuccessCallback<T>,
    onError?: ErrorCallback
  ): Promise<ResponseDto<T>> {
    try {
      const response = await this.axiosInstance.post<T>(url, data, config);
      const result = this.defaultSuccessHandler(response);
      onSuccess?.(result);
      return result;
    } catch (error: any) {
      const errorMessage = this.defaultErrorHandler(error);
      onError?.(errorMessage);
      return { success: false, message: errorMessage };
    }
  }

  // Méthode PUT
  async put<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
    onSuccess?: SuccessCallback<T>,
    onError?: ErrorCallback
  ): Promise<ResponseDto<T>> {
    try {
      const response = await this.axiosInstance.put<T>(url, data, config);
      const result = this.defaultSuccessHandler(response);
      onSuccess?.(result);
      return result;
    } catch (error: any) {
      const errorMessage = this.defaultErrorHandler(error);
      onError?.(errorMessage);
      return { success: false, message: errorMessage };
    }
  }

  // Méthode DELETE
  async delete<T>(
    url: string,
    config?: AxiosRequestConfig,
    onSuccess?: SuccessCallback<T>,
    onError?: ErrorCallback
  ): Promise<ResponseDto<T>> {
    try {
      const response = await this.axiosInstance.delete<T>(url, config);
      const result = this.defaultSuccessHandler(response);
      onSuccess?.(result);
      return result;
    } catch (error: any) {
      const errorMessage = this.defaultErrorHandler(error);
      onError?.(errorMessage);
      return { success: false, message: errorMessage };
    }
  }

  // Méthode PATCH
  async patch<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
    onSuccess?: SuccessCallback<T>,
    onError?: ErrorCallback
  ): Promise<ResponseDto<T>> {
    try {
      const response = await this.axiosInstance.patch<T>(url, data, config);
      const result = this.defaultSuccessHandler(response);
      onSuccess?.(result);
      return result;
    } catch (error: any) {
      const errorMessage = this.defaultErrorHandler(error);
      onError?.(errorMessage);
      return { success: false, message: errorMessage };
    }
  }
}
