<div class="validate-form">
  <div class="box overflow-x-auto">
    <form [formGroup]="validateMailForm">
      <div class="input-form">
        <div class="d-flex flex-column gap-20">
          <div class="font-bold text-xl mb-1">Activez votre compte</div>
          <p-inputOtp [length]="length" formControlName="code" [integerOnly]="true" *ngIf="email"/>
          <p-floatLabel *ngIf="!email">
            <input id="email" type="email" pInputText formControlName="email" />
            <label for="email">Adresse Email</label>
          </p-floatLabel>
          <div class="d-flex flex-wrap justify-center gap-10">
            <div class="flex justify-content-between mt-5 align-self-stretch">
              <p-button [label]="(timer == 0) ?'Resend Code' : 'Resend Code ('+timer+')'" (click)="resendMail()" [link]="true" class="p-0" *ngIf="emailInit" [disabled]="timer > 0"/>
              <p-button type="submit" (click)="onSubmit()" label="Valider" severity="help" *ngIf="!isSubmitted"></p-button>
              <p-progressSpinner ariaLabel="loading" *ngIf="isSubmitted" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>


