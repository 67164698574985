import { Component } from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {FloatLabelModule} from 'primeng/floatlabel';
import {InputTextModule} from 'primeng/inputtext';
import {StyleClassModule} from 'primeng/styleclass';
import {PasswordModule} from 'primeng/password';
import {ButtonDirective} from 'primeng/button';
import {LoginService} from '../../../Services/Auth/login.service';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ReactiveFormsModule, FloatLabelModule, InputTextModule, StyleClassModule, PasswordModule, ButtonDirective, RouterLink],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  constructor(private loginService: LoginService) {}

  loginForm = new FormGroup({
    name: new FormControl(''),
    password: new FormControl(''),
  })

  async login() {

    await this.loginService.login(this.loginForm.value.name!, this.loginForm.value.password!);
  }
}
