import {Component, OnInit} from '@angular/core';
import {HttpService, ResponseDto} from '../../../../Services/http.service';
import {environment} from '../../../../../environnements/environment';
import {Article} from '../../../../Models/article.model';
import {Response} from '../../../../Models/response.model';
import {ArticleComponent} from '../article/article.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-favorites',
  standalone: true,
  imports: [
    ArticleComponent, CommonModule
  ],
  templateUrl: './favorites.component.html',
  styleUrl: './favorites.component.scss'
})
export class FavoritesComponent implements OnInit {

  articles: Article[] = [];

  constructor(private httpService: HttpService) {
  }

  async ngOnInit(): Promise<void> {
    await this.getFavorites();
  }

  public async getFavorites(): Promise<void> {
    var res : ResponseDto<Article[]>  = await this.httpService.get<Article[]>('Article/getArticlesHomePage');
    if(res.success && res.data){
      this.articles = res.data;
    }
  }
}
