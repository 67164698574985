import {Component, Input, OnInit} from '@angular/core';
import {Button} from 'primeng/button';
import {FloatLabelModule} from 'primeng/floatlabel';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {NgIf} from '@angular/common';
import {PaginatorModule} from 'primeng/paginator';
import {PasswordModule} from 'primeng/password';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {HttpService, ResponseDto} from '../../../Services/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MottoHabbo} from '../../../Models/motto-habbo';
import {InputOtpModule} from 'primeng/inputotp';
import {Token} from '../../../Models/token';

@Component({
  selector: 'app-activate-account',
  standalone: true,
  imports: [
    Button,
    FloatLabelModule,
    InputSwitchModule,
    InputTextModule,
    NgIf,
    PaginatorModule,
    PasswordModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    InputOtpModule
  ],
  templateUrl: './activate-account.component.html',
  styleUrl: './activate-account.component.scss'
})
export class ActivateAccountComponent implements OnInit {
  validateMailForm!: FormGroup;
  isSubmitted = false;
  isDisabled = false;
  delay = 60;
  timer:number = this.delay;
  fromLogin = false;

  @Input('email') email?: string | null;
  emailInit: string | null | undefined = this.email;
  interval: any;
  protected readonly length = 8;
  constructor(private httpService: HttpService, private router: Router, private route: ActivatedRoute) {
    this.validateMailForm = new FormGroup({
      code: new FormControl(''),
      email: new FormControl(''),
    });
  }

  async ngOnInit(): Promise<void> {

    this.route.queryParams.subscribe(async (params) => {
      const optionalParam = params['email'];
      if (optionalParam && optionalParam !== this.email && this.email == null) { // Vérifie si l'email a changé
        console.log('Paramètre optionnel détecté : ' + optionalParam);
        this.email = optionalParam;
        this.emailInit = optionalParam;
        this.fromLogin = true;
        this.validateMailForm.removeControl('email');
        this.timer = 0;
        await this.resendMail();
      }
    });
    if(this.email){
      this.emailInit = this.email;
      this.timer = this.delay;
      this.startTimer();
    }
  }

  // Méthode appelée lors de la soumission du formulaire
  async onSubmit() :Promise<void> {
    if (this.validateMailForm.valid) {
      this.isSubmitted = true;
      console.log("form " + this.validateMailForm.value);
      if(this.validateMailForm.value.email){
        this.validateMailForm.removeControl('code');
        var res:ResponseDto<null> = await this.httpService.get('auth/SendConfirmationEmail', this.validateMailForm.value);
        if(res.success){
          this.validateMailForm.addControl('code', new FormControl(''));
          this.emailInit = this.validateMailForm.value.email;
          this.email = this.validateMailForm.value.email;
          this.isSubmitted = false;
          this.startTimer();
        } else {
          this.isSubmitted = false;
        }
      } else {
        // this.validateMailForm.patchValue({ email: this.email });
        var res2:ResponseDto<Token> = await this.httpService.post('auth/confirmEmail', { email: this.email, code: this.validateMailForm.value.code });
        if(res2.success) {
          this.router.navigateByUrl('/'); // Redirige l'utilisateur vers la page d'accueil
        } else {
          this.isSubmitted = false;

        }
      }
    } else {
      this.validateMailForm.markAllAsTouched(); // Affiche les erreurs sur tous les champs
    }
  }

  async resendMail() :Promise<void> {
    if(this.timer == 0){
      this.isDisabled = true;
      var res:ResponseDto<null> = await this.httpService.get('auth/SendConfirmationEmail', { email: this.email });
      if(res.success){
        this.startTimer();
      }
    }
  }

  startTimer() {
    if (this.interval) {
      clearInterval(this.interval); // Arrête l'interval existant
    }

    this.timer = this.delay;
    this.isDisabled = true; // Désactive le bouton ou autre fonctionnalité liée au timer
    this.interval = setInterval(() => {
      if (this.timer === 0) {
        clearInterval(this.interval);
        this.interval = null; // Réinitialise l'interval pour éviter des conflits
        this.isDisabled = false; // Réactive la fonctionnalité
      } else {
        this.timer--;
      }
    }, 1000);
  }
}
