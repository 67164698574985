<div *ngIf="toasts" class="toast-container">
  <div
    *ngFor="let toast of toasts"
    class="toast"
    [ngClass]="toast.severity"
    (click)="removeToast(toast)"
  >
    <span class="icon">{{ toast.icon }}</span>
    <div class="content">
      <strong>{{ toast.summary }}</strong>
      <p>{{ toast.detail }}</p>
    </div>
  </div>
</div>
