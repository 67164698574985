<!--<app-login/>-->
<div class="register-form" *ngIf="email == null">
  <div class="">
    <h1>Inscription</h1>
  </div>
  <div class="box overflow-x-auto">
    <form [formGroup]="registerForm">
      <div class="input-form">
        <div class="d-flex flex-column gap-30">
          <div class="d-flex flex-wrap justify-center gap-30">
            <p-floatLabel>
              <input pInputText id="username" formControlName="name" class="my-custom-input"  />
              <label for="username">Username</label>
            </p-floatLabel>
            <p-floatLabel>
              <input pInputText id="email" formControlName="email" class="my-custom-input"  />
              <label for="email">Adresse Mail</label>
            </p-floatLabel>
          </div>
          <div class="d-flex flex-wrap justify-center gap-30">
            <p-floatLabel>
              <input pPassword id="password" type="password" class="my-custom-input" formControlName="password" />
              <label for="password">Mot de passe</label>
            </p-floatLabel>
            <p-floatLabel>
              <input pPassword id="ConfirmPassword" type="password" class="my-custom-input"  formControlName="confirmPassword" />
              <label for="ConfirmPassword">Confirmation mot de passe</label>
            </p-floatLabel>
          </div>
          <div class="d-flex flex-wrap justify-center gap-30">
            <div class="d-flex flex-column align-content-center">
              <label for="habbo">Profil Habbo</label>
              <p-inputSwitch id="habbo" [(ngModel)]="habbo" [ngModelOptions]="{standalone: true}" />
            </div>
          </div>
          <div class="d-flex flex-wrap justify-center gap-10" *ngIf="habbo == true">
            <div>
              <p-floatLabel >
                <input pInputText (onchange)="setHabbo(!habbo)" id="habboName" type="text" class="my-custom-input"  formControlName="habboName" />
                <label for="habboName">Pseudo habbo</label>
              </p-floatLabel>
            </div>
            <p-button label="Vérifier" (click)="checkHabboName()" severity="help" *ngIf="!habboMoto && !habboSubmited"></p-button>
            <p-progressSpinner *ngIf="habboSubmited"/>
          </div>
          <div class="d-flex flex-wrap justify-center gap-10" *ngIf="habboMoto">
            <div>
              <small class="text-success">Veuillez définir cette humeur avant de valider : {{ habboMoto.uniqueId }}</small>
            </div>
          </div>
          <div class="d-flex flex-wrap justify-center gap-10">
            <p-button type="submit" (click)="onSubmit()" label="Valider inscription" severity="help" *ngIf="!isSubmitted"></p-button>
            <p-progressSpinner ariaLabel="loading" *ngIf="isSubmitted" />
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<app-activate-account *ngIf="email != null" [email]="email"></app-activate-account>



