import {Component, Input} from '@angular/core';
import {Article} from '../../../../Models/article.model';
import {DatePipe, NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'app-article',
  standalone: true,
  imports: [
    DatePipe,
    NgOptimizedImage
  ],
  templateUrl: './article.component.html',
  styleUrl: './article.component.scss'
})
export class ArticleComponent {
  @Input() article!: Article;

  public async like(): Promise<void> {
    console.log(this.article);
  }

  public async comment(): Promise<void> {

  }
}
