<div id="main">
  <app-toast/>
  <div class="header">
    <div class="backdrop">
      <app-header></app-header>
    </div>
  </div>
  <div class="content">
    <div class="content-limited">
      <div *ngIf="setting == null">
        Chargement....
      </div>
      <div *ngIf="setting?.maintenance == true">
        <app-maintenance/>
      </div>
      <div *ngIf="setting != null && setting.maintenance == false" class="w-100">
        <router-outlet/>
      </div>
    </div>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>



