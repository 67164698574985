<div class="login-form">
  <div class="box">
    <form [formGroup]="loginForm">
      <div class="input-form">
        <p-floatLabel>
          <input pInputText id="username" formControlName="name" class="my-custom-input" />
          <label for="username">Username</label>
        </p-floatLabel>
        <p-floatLabel>
          <input pPassword id="password" type="password" class="my-custom-input" formControlName="password" />
          <label for="password">Mot de passe</label>
        </p-floatLabel>
        <div class="button-div">
          <div>
            <button pButton label="Se connecter" [raised]="true" size="small" (click)="login()" severity="success" class="btn-success"></button>
          </div>
          <div>
            <button pButton label="S'inscrire" [raised]="true" size="small" routerLink="/register"  severity="success" class="btn-success"></button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>


